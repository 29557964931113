body, .bg-wrap {
  position: relative;
}

.bg {
  position: absolute;
  left: 0;
  right: 0;
  height: 300px;
  z-index: -1;
}

.bg-top {
	top: 0;
	background: linear-gradient(#8AC2ED, #FFFFFF);
}

.bg-bottom {
	bottom: 0;
	background: linear-gradient(#FFFFFF, #87c063);
}

#flower1 {
  position: absolute;
  top: 10px;
  left: -125px;
}

#flower2 {
  position: absolute;
  top: -75px;
  right: -130px;
}

.navbar {
  margin-bottom: 1.5rem;
}

.navbar a {
  margin-bottom: 0;
  padding-top: 15px;
  padding-bottom: 15px;
  color: #777;
  font-weight: bold;
  text-align: center;
  border-bottom: 1px solid #d5d5d5;
  background-color: #e5e5e5;
  background-image: linear-gradient(#f5f5f5, #e5e5e5);
}
.navbar .active a,
.navbar .active a:hover,
.navbar .active a:focus {
  background-color: #ddd;
  background-image: none;
  box-shadow: inset 0 3px 7px rgba(0,0,0,.15);
}
.navbar li:first-child a {
  border-radius: 5px 5px 0 0;
}
.navbar li:last-child a {
  border-bottom: 0;
  border-radius: 0 0 5px 5px;
}
@media (min-width: 768px) {
  .navbar {
    margin-bottom: 3rem;
  }
  .navbar li > a {
    border-left: 1px solid #fff;
    border-right: 1px solid #d5d5d5;
  }
  .navbar li:first-child > a {
    border-left: 0;
    border-radius: 5px 0 0 5px;
  }
  .navbar li:last-child > a {
    border-radius: 0 5px 5px 0;
    border-right: 0;
  }
}

.navbar-toggler {
	border-color: #6f9dbf;
	position: absolute;
	top: -60px;
	right: 0;
	margin: 0;
}

/* background */

.sheet {
  position: relative;
  background: none repeat scroll 0 0 rgba(242, 240, 237, 0.3);
  border-radius: 5px;
  box-shadow: 0 0 5px 3px rgba(0, 0, 0, 0.15);
}

blockquote {
  border-left: 3px solid silver;
  background: #eee;
  padding: 5px;
}

blockquote p {
  margin: 0;
}